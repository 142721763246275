main.introduceDetail {
    margin-top: calc(40px + 83px);
    padding-bottom: 60px;
}
  .list > .item:nth-child(1) .itemBackGround {
    background-image: url('../../../public/images/introduce/service_sea_boats.png');
  }
  .list > .item:nth-child(2) .itemBackGround {
    background-image: url('../../../public/images/introduce/service_pagoda.png');
  }
  .list > .item:nth-child(3) .itemBackGround {
    background-image: url('../../../public/images/introduce/service_longden.png');
  }
  .list > .item:nth-child(4) .itemBackGround {
    background-image: url('../../../public/images/introduce/service_manypagoda.png');
  }
  .list > .item:nth-child(5) .itemBackGround {
    background-image: url('../../../public/images/introduce/service_mountain.png');
  }