  /*header*/
  header {
    background-color: var(--clr-main);
    padding-block: 15px;
    position: fixed; 
    z-index: 99;
    top: 0;
    right: 0;
    left: 0;
  }
  .headerMain.flexCenter {
    justify-content: space-between;
  }
  .logo {
    min-width: 125px;
  }
  .logo p {
    transform: translate(-15%, 25%);
  }
  .headerNavWrapper {
    align-items: start;
  }
  .headerNavWrapper
  ,.headerNavWrapper ul {
    gap: 4.1875em;
  }
  .headerNavWrapper li {
    max-width: 20ch;
  }
  .headerNavWrapper li a{
    text-transform: uppercase;  
  }
  .buttonGroup {
    background-color: var(--clr-white);
    border-radius: 11px;
    height: 2rem;
    overflow: hidden;
  }
  .toggleHeight {
    height: 4rem;
  }
  .buttonGroup > * {
    min-width: 2em;
    min-height: 2em;
    padding: 5px;
  }