/*outstanding*/
.flexCenter[data-card="outstanding"] {
  flex-wrap: nowrap;
  justify-content: start;
}
.outstandingWrapper {
    margin-top: calc(71px + 83px);
  }
  .cardList {
    overflow: hidden;    
    gap: 2.3125em;
    z-index: -1;
  }
  .cardList[data-card="outstanding"] > :nth-child(1){
    margin-left: 50px;
  }
  .cardList[data-card="outstanding"] > * {
    border-radius: 34px;
    min-width:  49.9375em;
    min-height: 31.5625em;
    padding: 53px 0;
    justify-content: space-between;
    overflow: hidden; 
    position: relative;
    margin-top: 39px;
  }
  .cardItem[data-card="outstanding"] img{
    position: absolute;
    inset: 0;
    transition: .5s ease all;
    transition-delay: .2s;
  }
  .cardItem[data-card="outstanding"]:is(:hover, :focus-visible) img{
    scale: 1.2;
  }
  .cardList[data-card="outstanding"] > *::after {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0.71;
    background: linear-gradient(270deg, #D9D9D9 47%, #002E30 90.5%);  
    mix-blend-mode: multiply;
    z-index: 1;
  }
  .cardItem[data-card="outstanding"] {
    transition: all 5s ease-in-out;
    transition-property: background-size;
    cursor: pointer;
  }
  .cardItemTopContent
  ,.cardItemBottomContent {
    padding: 0 32px;
    z-index: 2;
  }
  .cardItemTopContent span {
    font-size: var(--fs-24);
    text-transform: uppercase;
  }
  .cardItemTopContent p {
    max-width: 17ch;
    text-transform: uppercase;
  }
  .cardItemBottomContent.flexCenter {
    justify-content: space-between;
    align-items: end;
  }
  .cardItemBottomContent span {
    font-size: var(--fs-24);
  }
  .cardItemBottomContent button {
    font-size: var(--fs-24);
    padding: 11px 26px;
    cursor: pointer;
    transition: .3s ease;
    transition-delay: .2s;
  }
  a{
    transition: .3s ease all;
    transition-delay: .2s;
  }
  .cardItemBottomContent button:is(:hover, :focus-visible){
    background-color: var(--clr-main);
    color: var(--clr-white);
  }
  .cardItemBottomContent button:hover a {
    color: var(--clr-white);
  }