.packageWrapper {
    padding-block: 99px;
  }
  .filter {
    align-items: flex-start;
    padding-block: 39px;
    justify-content: space-between;
  }
  .filter.flexCenter {
    align-items: flex-start;
    justify-content: space-between;
  }
  .filter ul{
    gap: 45px;
  }
  .filter label{
    font-size: var(--fs-16);
  }
  .filter select {
    border: none;
    outline: none;
    font-weight: 200;
    font-size: var(--fs-12);
    min-width: 9.5rem;
    padding: 7px 7px 14px 14px;
  }
  .filter button {
    background-color: var(--clr-main);
    font-size: var(--fs-20);
    padding: 8px 29px;
    transition: .3s ease all;
    transition-delay: .2s;
  }
  .btnHover[data-button="green"]:hover {
    background-color: var(--clr-white);
    color: var(--clr-main);
    box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.3);
  }
  .cardList[data-card="package tour"].flexCenter{
    justify-content: start;
    flex-wrap: nowrap;
  }
  .cardList[data-card="package tour"] > * {
    min-width: 20.5rem;
    min-height: 35.438rem;
    border-radius: 53px;
    position: relative;
    overflow: hidden;
    flex-wrap: nowrap;
    gap: 33px;
    cursor: pointer;
  }
  .cardList[data-card="package tour"] > :nth-child(1){
    margin-left: 50px;
  }
  .cardItem[data-card="package tour"] > a {
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: var(--fs-33);  
    z-index: 1;
  }
  .cardItem[data-card="package tour"] {
    position: relative;
  }
  .cardItem[data-card="package tour"]::before {
    content: "";
    background: linear-gradient(180deg, rgba(0, 46, 48, 0.00) 70.5%, rgba(0, 46, 48, 0.53) 84.5%, #002E30 99.1%);
    inset: 0;
    z-index: 1;
    position: absolute;
  }
  .cardItem[data-card="package tour"] > img{  
    transition: .5s ease all;
    transition-delay: .2s;
  }
  .cardItem[data-card="package tour"]:hover > img {
      scale: 1.2;
  }
  .duration {
    gap: 18px;
  }
  .inputRangeWrapper {
    gap: 10px;
  }
  .inputRangeWrapper input{
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 20.125rem;
    height: 2px;
    background-color: var(--clr-text);
    position: relative;
  }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
  
      /* Dimensions of the thumb */
      width: 20px; /* Adjust as needed */
      height: 20px; /* Adjust as needed */
      
      /* Color and shape of the thumb */
      background-color: var(--clr-highlight); /* Color of the thumb */
      border-radius: 50%; /* Rounded shape */
      cursor: pointer; /* Show pointer cursor when hovering */
  }
  input[type=range]::-ms-track {
    background-color: black;
  }
  input[type=range]::before {
    content: '';
    width: 4px;
    height: 4px;
    background-color: var(--clr-main);
    position: absolute;
    border-radius: 50%;
    bottom: 50%;
    left: 0;
    transform: translate(-90%, 50%);
  }
  input[type=range]::after {
    content: '';
    width: 4px;
    height: 4px;
    background-color: var(--clr-main);
    position: absolute;
    border-radius: 50%;
    bottom: 50%;
    right: 0;
    transform: translate(90%, 50%);
  }
  .inputRangeWrapper p {
    font-size: var(--fs-16);
  }