main.packagetourdetail {
    margin-top: min(calc(30px + 83px));
}
/*reusable class*/
.active {
    opacity: 1;
}
.bg-box {
    background-color: var(--clr-box);
}
.fs-text {
    font-size: var(--fs-24);
}
/*carousel*/
.packageButtonGroup {
    padding-top: 20px;
}
.packageButtonGroup > button {
    transform: translateX(29px);
}
.carousel[data-type="package"] {
    padding-top: 44px;
}
.img-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    position: absolute;
    inset: 0;
    transition: .4s ease-in-out all;
}
.img-wrapper {
    min-width: 100%;
    min-height: 34.25rem;
    overflow: hidden;
}
.carousel {
    position: relative;
}
.carouselBtn {
    position: absolute;
    font-family: monospace;
    font-size: 24px;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-color: white;
    font-weight: bold;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}
#next {
    right: 2rem;
}
#prev {
    left: 2rem;
}
.packageButtonGroup button {
    background-color: transparent;
    min-width: 40px;
    min-height: 40px;
    padding-bottom: 40px;
}
/*filter*/
.filter[data-type="packagetour"] {
    margin-inline: auto;
    width: fit-content;
}
.filter[data-type="packagetour"] select{
    padding: 6px 10px 6px 7px;
}
.filter[data-type="packagetour"] li {
    display: flex;
    gap: 14px;
}
.inputRangeWrapper {
    transform: translateY(20px);
}
/*packageMain*/
.packageMain{
    margin-top: 44px;
}
.cardPackageList {
    padding-block: 55px 49px;
    column-gap: 19px;
    row-gap: 58px;
}
.cardPackageItemContent {
    max-width: 21.125rem;
}
.cardPackageItemContent p {
    max-width: 20ch;
}
.packageMain h1 {
    font-size: 80px;
}
.cardPackageItemImgWrapper {
    position: relative;
    max-width: 21.125rem;
    border-radius: 40px;
    overflow: hidden;
}
.cardPackageItem img {
    width: 100%;
}
.cardPackageItemImgWrapper > img:nth-child(2) {
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: .4s ease all;
    transition-delay: .2s;
}
.cardPackageItemImgWrapper:hover > img:nth-child(2) {
    opacity: 1;
    scale: 1.1;
}
.active {
    opacity: 1;
}