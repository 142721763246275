main.aboutusDetail {
    margin-top: calc(40px + 83px);
}
.aboutNumber {
    transform: translateY(-33px);
}
.aboutWrapper{
    gap: 70px;
    padding-block: 45px 38px;
    justify-content: space-between;
}
.aboutBackBtn {
    min-width: 38px;
    min-height: 38px;
    background-color: transparent;
    margin-top: 15px;
    transform: translateX(30px);
}
.aboutNumber {
    width: fit-content;
    height: fit-content;
    font-size: 128px;
}
.aboutContent p {
    grid-column: span 12;
    text-align: justify;
}
.aboutContent[data-type="left"] {
    margin-top: 33px;
}
.aboutWrapper img {
    width: 100%;
}
.aboutRight {
    background-color: var(--clr-main);
    border-radius: 30px;
    overflow: hidden;
    padding: 31px 60px;
}
.aboutContent p{
    margin-bottom: 42px;
}