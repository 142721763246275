.introduceWrapper{
    padding-bottom: 53px;
  }
  .cardList[data-card="introduce"] {
    margin-top: 40px;
    gap: 25px;
  }
  .cardList[data-card="introduce"] > * {
    min-height: 14.188rem;
    display: flex;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: center;
    border-radius: 40px;
    padding: 0 73px;
    cursor: pointer;
  }
  .cardList[data-card="introduce"] > :nth-child(1) {
    background-image: url('../../../public/images/introduce/aboutus.png');
  }
  .cardList[data-card="introduce"] > :nth-child(2) {
    background-image: url('../../../public/images/introduce/ourcustomer.png');
  }
  .cardList[data-card="introduce"] > :nth-child(3) {
    background-image: url('../../../public/images/introduce/services.png');
  }
  .cardItem[data-card="introduce"] {
    position: relative;
  }
  .cardItem[data-card="introduce"] a {
    z-index: 1;
  }
  .cardItem[data-card="introduce"]:not(:nth-child(2)):before {
    content: "";
    position: absolute;
    background: linear-gradient(-90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.73) 66.6%, rgba(255, 255, 255, 0.88) 86.44%);
    inset: 0;
  }
  .cardItem[data-card="introduce"]:nth-child(2):before {
    content: "";
    position: absolute;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.73) 66.6%, rgba(255, 255, 255, 0.88) 86.44%);
    inset: 0;
  }
  
   .card {
    align-items: center;
    gap: 34px;
    padding: 24px 68px;
    max-width: 47.063rem;
  }
  .card > i img {
    max-width: 108px;
    max-height: 108px;
  }
  .card > p {
    max-width: 50ch;
  }
  .card > button {
    background-color: var(--clr-main);
    padding: 11px 26px;
    font-size: 24px;
    transition: .3s ease all;
    transition-delay: .2s;
  }