/*footer*/
.footerWrapper {
    background-color: var(--clr-main);
  }
  .footerMain {
    color: var(--clr-white);  
    padding-block: 25px;
  }
  .footerMain.flexCenter {
    justify-content: space-between;
  }
  .footerLeftContent {
    max-width: 23.875rem;
    gap: 6px;
  }
  .footerLeftContent h3 {
    font-size: var(--fs-19);
    max-width: 20ch;
  }
  .footerLeftContent p {
    font-size: var(--fs-16);
  }
  .f_logo {
    min-width: 196px;
    scale: 1.2;
  }
  .footerRight {
    gap: 12px;
  }
  .footerRight p{
    font-size: var(--fs-16);
  }