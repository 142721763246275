/*infor*/
.formWrapper {
    margin: 20px 0 99px 0;
    background-image: url('../../../public/images/infor/backgroundSendingBox.png');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: center;
    border-radius: 0 0 64px 64px;
    min-height: 52.125rem; 
    position: relative;
  }
  form {
    background-color: rgba(255, 255, 255, 0.22);
    max-width: 39.313rem;
    border-radius: 40px;
    padding: 23px 36px;
    position: absolute;
    top: 110px;
    left: 88px;
  }
  
  form h2 {
    font-size: 32px;
  }
  
  form label {
    font-size: 24px;
  }
  form input {
    min-height: 40px;
    border-radius: 100vmax;
    padding: 5px 20px;
    border: none;
    outline: none;
    font-family: var(--ff-main-medium);
  }
  form textarea {
    border-radius: 29px;
    max-height: 142px;
    padding: 5px 20px;
  }
  .formFields {
    margin-top: 20px;
    gap: 13px;
  }
  form button {
    max-width: fit-content;
    border-radius: 14px;
    font-size: var(--fs-20);
    background-color: var(--clr-main);
    padding: 7px 17px;
    margin-top: 20px;
    margin-inline: auto;
    transition: .3s ease all;
    transition-delay: .2s;
  }
  dialog {
    border: none;
    outline: none;
    position: fixed;
    z-index: 10;
  }
  dialog[data-type="home"] {
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 40px;
    
  }