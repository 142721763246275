@font-face {
    font-family: MonserratExtraBold;
    src: url('../public/fonts/Monserrat/Montserrat-ExtraBold.ttf');
  }
  @font-face {
    font-family: MonserratBold;
    src: url('../public/fonts/Monserrat/Montserrat-Bold.ttf');
  }
  @font-face {
    font-family: MonserratMedium;
    src: url('../public/fonts/Monserrat/Montserrat-Medium.ttf');
  }
  @font-face {
    font-family: MonserratRegular;
    src: url('../public/fonts/Monserrat/Montserrat-Regular.ttf');
  }
  @font-face {
    font-family: MonteCarlo;
    src: url('../public/fonts/MonteCarlo/MonteCarlo-Regular.ttf');
  }
  @font-face {
    font-family: MonserratSemibold;
    src: url('../public/fonts/Monserrat/Montserrat-SemiBold.ttf');
  }
  @font-face {
    font-family: MonserratExtralight;
    src: url('../public/fonts/Monserrat/Montserrat-ExtraLight.ttf');
  }
  @font-face {
    font-family: Monserratlight;
    src: url('../public/fonts/Monserrat/Montserrat-Light.ttf');
  }
  :root {
  
      /* set color */
      --clr-main: #0e8691;
      --clr-white: #ffffff;
      --clr-text: #595959;
      --clr-highlight: #fb6868;
      --clr-box: #d9d9d9;
      --clr-black: #000000;
  
      /*font family*/
      --ff-logo: MonteCarlo;
      --ff-main-bold: MonserratBold;
      --ff-main-extraBold: MonserratExtraBold;
      --ff-main-medium: MonserratMedium;
      --ff-main-regular: MonserratRegular;
      --ff-main-semibold: MonserratSemibold;
      --ff-main-extralight: MonserratExtralight;
      --ff-main-light: Monserratlight;
  
      /*font size*/
      --fs-27: 1.688rem;
      --fs-28: 1.75rem;
      --fs-20: 1.25rem;
      --fs-19: 1.188rem;
      --fs-48: 3rem;
      --fs-24: 1.5rem;
      --fs-40: 2.5rem;
      --fs-16: 1rem;
      --fs-12: 0.75rem;
      --fs-33: 2.063rem;
      --fs-32: 2rem;
      --fs-64: 4rem;
      --fs-19: 1.188rem;
      --fs-80: 5rem;
  
      --fs-logo: var(--fs-27);
      --fs-primaryHeading: var(--fs-48);
      --fs-secondaryHeading: var(--fs-40);
      --fs-lg-100: var(--fs-64);    
      --fs-lg-200: var(--fs-80);
      --fs-nav: var(--fs-20);        
      --fs-sm-100: var(--fs-16);
      --fs-md-title: var(--fs-28);
  }
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  /* Remove default margin */
  * {
      margin: 0;
      padding: 0;
      font: inherit;
  }
  
  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul[role='list'],
  ol[role='list'] {
    list-style: none;
  }
  
  /* Set core root defaults */
  html:focus-within {
    scroll-behavior: smooth;
  }
  
  html,
  body {
      height: 100%;
      scroll-behavior: smooth;
  }
  
  /* Set core body defaults */
  body {
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }
  
  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }
  a{
    text-decoration: none;
  }
  /* Make images easier to work with */
  img,
  picture,
  svg {
    max-width: 100%;
    display: block;
  }
  
  /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
     scroll-behavior: auto;
    }
    
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
  body {
    max-width: 1512px;
    margin-inline: auto;
  }
  /* reusable class */
  .container {
    width: min(1412px, 100% - 20px);
    margin-inline: auto;
  }
  .clr-green {
    color: #14CA26;
  }
  .clr-main {
    color: var(--clr-main);
  }
  .clr-white {
    color: var(--clr-white);
  }
  .clr-black {
    color: var(--clr-black);
  }
  .clr-box {
    color: var(--clr-box);
  }
  .clr-text {
    color: var(--clr-text);
  }
  .clr-highlight {
    color: var(--clr-highlight);
  }
  
  .ff-logo {
    font-family: var(--ff-logo);
  }
  .ff-main-bold {
    font-family: var(--ff-main-bold);
  }
  .ff-main-extraBold {
    font-family: var(--ff-main-extraBold);
  }
  .ff-main-semibold {
    font-family: var(--ff-main-semibold);
    font-weight: 600;
  }
  .ff-main-regular {
    font-family: var(--ff-main-regular);
  }
  .ff-main-medium {
    font-family: var(--ff-main-medium);
  }
  .ff-main-extralight{
    font-family: var(--ff-main-extralight);
  }
  .ff-main-light{
    font-family: var(--ff-main-light);
  }
  
  .fs-md-title {
    font-size: var(--fs-md-title);
  }
  .fs-primaryHeading {
    font-size: var(--fs-primaryHeading);
  }
  .fs-secondaryHeading {
    font-size: var(--fs-secondaryHeading);
  }
  .fs-lg-100 {
    font-size: var(--fs-lg-100);
  }
  .fs-lg-200 {
    font-size: var(--fs-lg-200);
  }
  .fs-logo {
    font-size: var(--fs-logo);
  }
  .fs-nav {
    font-size: var(--fs-nav);
  }
  .fs-sm-100 {
    font-size: var(--fs-sm-100);
  }
  .justify-content-end {
    justify-content: end;
  }
  .columns-auto {
    display: grid;
    gap: 1rem;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .pointer-none {
    pointer-events: none;
  }
  @media (min-width: 50em) {
    .columns-auto {
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }
  }
  /*reusable class of component*/
  .button {
    border-radius: 14px;
    border: none;
    outline: none;
  }
  button[data-button="translate"]:not([class]) {
    border-radius: 11px;
  }
  .buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: start;
    height: 50px;
  }
  .buttonGroup {
    transition: all .5s ease;
    border: none;
    outline: none;
  }
  #vn {
    background-color: transparent;
    transition: all .5s ease;
  }
  #en {
    transition: all .5s ease;
    background-color: var(--clr-main);
    color: var(--clr-white);
    border-radius: 11px;
    border: none;
    outline: none;
  }
  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .flexColCenter {
    display: flex;
    flex-direction: column;
  }
  .text-center {
    text-align: center;
  }
  .text-uppercase {
    text-transform: uppercase;
  }

  /*services, our customer*/
  .BackBtnBlack{
    position: fixed;
    top: calc(40px + 83px);
    z-index: 9;
    border: none;
    outline: none;
    background-color: transparent;
  }
  .list[data-type="services"]{
    margin-top: 52px;
    gap: 26px;
  }
  .itemBackGround[data-type="services left"]
  ,.itemBackGround[data-type="services right"]{
    min-width: 100%;
    height: 22.5rem; 
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    border-radius: 40px;
    padding: 54px 70px;
    align-items: center;   
    position: relative;
  }
  .itemBackGround[data-type="services left"] {
    justify-content: start;
  }
  .itemBackGround[data-type="services right"] {
    justify-content: end;
  }
  .itemText {
    max-width: 28.938rem;  
    max-width: 28.938rem;
    gap: 32px;
    z-index: 1;
    position: relative;
  }
  .itemText[data-type="services left"] {
    text-align: left; 
  }
  .itemText[data-type="services right"] {
    text-align: right; 
  }
  .itemBackGround::before {
    content: "";
    position: absolute;
    inset: 0;
  }
  .itemBackGround[data-type="services left"]::before {
    background: linear-gradient(-90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.83) 66.6%, #FFF 86.44%);
  }
  .itemBackGround[data-type="services right"]::before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.83) 66.6%, #FFF 86.44%);
  }