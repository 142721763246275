
main {
    margin-top: calc(30px + 83px);
}
/*carousel*/
.img-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    position: absolute;
    inset: 0;
    transition: .4s ease-in-out all;
}
.img-wrapper {
    min-width: 100%;
    min-height: 34.25rem;
    overflow: hidden;
}
.carousel {
    position: relative;
}
.carouselBtn {
    position: absolute;
    font-family: monospace;
    font-size: 24px;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-color: white;
    font-weight: bold;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}
#next {
    right: 2rem;
}
#prev {
    left: 2rem;
}
.back {
    background-color: transparent;
    position: absolute;
    left: 60px;
    top: 20px;
    z-index: 2;
}
.infor {
    max-width: 130ch;
    padding-bottom: 54px;
}
.inforButtonGroup {
    gap: 25px;
    margin-top: 31px;
}
.inforButtonGroup > * {
    padding: 11px 26px;
    font-size: var(--fs-24);
    border-radius: 14px;
    border: none;
    outline: none;
    transition: .3s ease all;
    transition-delay: .2s;
}
.inforButtonGroup > :nth-child(1) {
    background-color: var(--clr-main);
}
.inforButtonGroup > :nth-child(2) {
    background-color: var(--clr-white);
    border: 1px solid var(--clr-main);
}
.inforButtonGroup > :nth-child(1):is(:hover, :focus-visible) {
    background-color: var(--clr-white);
    color: var(--clr-main);
    border: 1px solid var(--clr-main);
}
.inforButtonGroup > :nth-child(2):is(:hover, :focus-visible) {
    background-color: var(--clr-main);
    color: var(--clr-white);
}
.row.flexCenter {
    gap: 129px;
    align-items: start;
    justify-content: start;
}
.row[data-gap="sm"].flexCenter {
    gap: 52px;
}
.detailsLeftTop {
    display: flex;
    flex-direction: column;
    gap: 34px;
    margin-bottom: 73px;
}
.detailsLeftBottom > :nth-child(1) {
    justify-content: start;
    align-items: center;
    gap: 9px;
    margin-bottom: 15px;
}
.detailsLeftBottom > p {
    font-size: var(--fs-24);
    max-width: 38ch;
}
.detailsRight {
    width: min(44.313rem, 100%);
    height: 789px;  
    overflow: hidden;
    position: relative;
}
.detailsRightItem {
    border-radius: 40px;
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: opacity .3s ease-in-out;
}


/*schedule*/
.schedule h1 {
    margin-bottom: 38px;
}
.scheduleMain.columns-auto {
    column-gap: 83px;
}
.scheduleLeft{
    display: grid;
    grid-template-areas:
    "scheduleLeft scheduleRightTop"
    "scheduleLeft scheduleRightBottom";  
    row-gap: 24px;
    column-gap: 26px;
}
.scheduleLeft > * {
    width: 100%;
    height: 100%;
}
.scheduleLeft > :nth-child(1) { 
    grid-area: scheduleLeft;
}
.scheduleLeft > :nth-child(2) {
    grid-area: scheduleRightTop;
}
.scheduleLeft > :nth-child(2) {
    grid-area: scheduleRightBottom;
}
.scheduleRight {
    gap: 34px;
}
.scheduleRightTop > :nth-child(2) {
    max-width: 20ch;
}
.scheduleRightBottomItem > :first-child {
    font-size: var(--fs-24);
}
.scheduleRightBottomItemLine {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.scheduleRightBottomItemLine span{
    width: 2px;
    height: 100%;
    background-color: var(--clr-main);
    position: relative; 
}
.scheduleRightBottomItemLine span::before {
    content: "";
    position: absolute;
    background-color: var(--clr-highlight);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: 0;
    right: 0;
    transform: translateX(-40%);
    top: 45px;
}
.scheduleRightBottomItemLine span::after {
    content: "";
    position: absolute;
    background-color: var(--clr-highlight);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: 0;
    right: 0;
    transform: translateX(-40%);
    top: 93px;
}
.scheduleRightBottomItemContent {
    padding-top: 31px;    
    grid-column: span 8;
    gap: 14px;
}
.scheduleRightBottomItemContent > *.flexCenter,
.scheduleRightBottomItemContent[data-type="oneRow"],
.scheduleRightBottomItemContent[data-type="dayTwo"] {
    justify-content: start;
    align-items: start;
    gap: 18px;
    flex-wrap: nowrap;
}
.scheduleRightBottomItemContent > :nth-child(1) > p:first-child,
.scheduleRightBottomItemContent > :nth-child(2) > p:first-child,
.scheduleRightBottomItemContent[data-type="dayTwo"] > * > p:first-child {
    font-size: var(--fs-24);
    font-style: italic;
    width: auto;
}
.scheduleRightBottomItemContent > :nth-child(2) > p:last-child {
    max-width: 38ch;
}
.scheduleRightBottomItemContent[data-type="oneRow"] > :nth-child(1) {
    font-size: var(--fs-24);
    font-style: italic;
}
.scheduleRightBottomItemContent[data-type="oneRow"] > :nth-child(2) > p:first-child
,.scheduleRightBottomItemContent[data-type="dayTwo"] p:last-child {
    font-size: var(--fs-nav);
    font-style: normal;
}
.scheduleRightBottomItemLine[data-type="oneRow"] span::after {
    display: none;
}
.schedule {
    padding-bottom: 63px;
}

.scheduleMain[data-type="dayTwo"] {
    padding-top: 70px;
    justify-content: space-between;
}
.scheduleRightBottomItemLine .dotCustom::before {
    top: -6px;
}
.scheduleRightBottomItemLine .dotCustom::after {
    top: 8.125rem;
}
.scheduleRightBottomItemLine .dotCustom[data-dot="low"]::after {
    top: 7.3rem;
}
.scheduleRightBottomItemLine .dotCustom[data-dot="low"]::before {
    top: 45px;
}
.dialogForm {
    display: flex;
    background-color: var(--clr-main);
    color: var(--clr-white);
    position: fixed;
    min-width: 39.313rem;
    flex-direction: column;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    top: 20%;
}
.dialogForm h2 {
    max-width: 18ch;
    margin-inline: auto;
}
.dialogForm > :nth-child(2) {
    gap: 13px;
    margin-top: 20px;
}
.dialogForm textarea
,.dialogForm input {
    font-size: 20px;
    font-family: var(--ff-main-medium);
}
.active {
    opacity: 1;
}